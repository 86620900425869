<template scoped>
  <Create
    :resourceServer="resourceServer"
    :resourceType="resourceType"
    :instancePath="instancePath"
    :authorizationHub="false"
  ></Create>
</template>

<script scoped>
export default {
  props: {
    resourceServer: String,
    resourceType: String,
    instancePath: String,
  },
  data: function () {
    return {};
  },
  components: {
    Create: () =>
      import("cms_infrastructure_web/src/sdk/ResourceHub-Portal/Create/ResourceHub-Create.vue"),
  },
};
</script>